import { NonceProvider } from "react-select";
import styled from "styled-components";

export const ModalComponent = styled.div`
  display: ${(props) => props.modalDisplayStyle};
  position: fixed;
  z-index: 15;
  padding-top: 100px;
  padding-bottom: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  @media (max-width: 414px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;
export const ModalContent = styled.div`
  text-align: center;
  justify-content: center;
  left: 50%;
  top: 100%;
  background-color: #fefefe;
  margin: auto;
  padding: 1rem auto;
  border: 1px solid #888;
  width: 40%;
  h3 {
    padding: 2rem 1rem 0.5rem 1rem;
  }
  /* @media (max-width: 935px) {
    width: 60%;
  } */
  @media (max-width: 935px) {
    width: 50%;
    padding: 1rem 3%;
  }
  @media (max-width: 414px) {
    width: 90%;
    h3 {
      font-size: 1.1rem;
      padding: 1.5rem 1rem 0.5rem 1rem;
    }
  }
`;
export const Form = styled.form`
  text-align: center;
  /* display: flex; */
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 1rem auto;
  margin: 1rem auto;
  display: flex;
  flex-wrap: wrap;
  div {
    margin: 0 5%;
    @media (min-width: 1450px) {
      margin: 0 10%;
    }
    @media (max-width: 1388px) {
      margin: 0 1%;
    }
    padding: 0.5rem 2%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: 414px) {
      padding: 0.5rem 1rem;
    }
  }
  p {
    color: black;
    font-size: 0.9rem;
    padding-top: 1rem;
    line-height: 1.3rem;
    border-bottom: lightgray 2px solid;
  }
  label {
    font-size: 0.7rem;
    color: navy;
    width: 12rem;
    div {
      /* padding: 1px; */
      margin: 0;
    }
    @media (min-width: 1500px) {
      width: 15rem;
    }
    @media (max-width: 1388px) {
      width: 12rem;
      margin: 1rem;
    }
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: 1.5rem;
    @media (max-width: 414px) {
      margin: 1rem 0;
      width: 100%;
      font-size: 0.8rem;
    }
  }
  input {
    margin-top: 0.5rem;
    padding: 0.5rem;
    font-size: 0.9rem;
    border: none;
    color: black;
    padding-left: 0;
    border-bottom: lightsteelblue 2px solid;
    ::placeholder {
      color: lightgray;
      font-size: 0.8rem;
    }
  }
  select {
    margin-top: 1.5rem;
    height: 1.5rem;
    option {
      padding: 0.5rem 0;
    }
  }
  button {
    margin: 1rem auto;
    width: ${(props) => props.width};
    div {
      width: fit-content;
    }
    span {
      width: fit-content;
      padding: 10px 0px;
    }
  }
`;
export const SelectStyles = {
  // margin: "0",
  // display: "flex",
  // flexDirection: "row",
  control: (_, { selectProps: { width } }) => ({
    ...provided,
    width: width,
    height: "2.5rem",
    fontSize: "0.9rem",
    border: "none",
    margin: "0",
    borderBottom: "1px solid lightgray",
    cursor: "pointer",
  }),
  menu: (_, { selectProps: { width, height } }) => ({
    width: width,
    fontSize: "0.9rem",
    border: "1px solid lightgray",
    color: "black",
    backgroundColor: "whitesmoke",
    cursor: "pointer",
  }),
  container: (provided, state) => ({
    ...provided,
    height: "2.5rem",
    padding: "1px",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "2.5rem",
  }),

  singleValue: (provided, state) => {
    const height = "1.5rem";
    const padding = "0";
    return { ...provided, height, padding };
  },
  valueContainer: (provided, state) => {
    const height = "1rem";
    const padding = "0";
    return { ...provided, height, padding };
  },
};

export const Button = styled.button`
  border: none;
  color: white;
  /* background-color: #740dce; */
  /* background-color: lightseagreen; */
  margin: 0rem 2rem 1.5rem 2rem;
  padding: 0.7rem 1.5rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  cursor: pointer;
  width: fit-content;
  @media (max-width: 414px) {
    font-size: 0.9rem;
    margin: 1rem;
  }
`;

export const CloseStyle = styled.span`
  color: #aaaaaa;
  float: right;
  padding: auto;
  font-size: 28px;
  font-weight: bold;
`;
