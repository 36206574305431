import styled from "styled-components";
import { Section } from "./globalStyles";

export const RidesSection = styled.div`
  display: block;
  margin: 2rem 5% 2rem 5%;
  padding: 0;
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`;

export const DateWrapper1 = styled.div`
  ${tw`flex flex-col justify-start`};
  height: fit-content;
  div {
    width: 100%;
  }
`;

export const DateWrapper2 = styled.div`
  ${tw`flex w-full`};
  flex-direction: column;
  span,
  input {
    width: 100%;
  }
  .dateTime {
    ${tw`text-sm border border-gray-600 rounded-lg`}
  }
  .rdtCounters {
    display: -webkit-flex;
  }
`;
