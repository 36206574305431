import styled from "styled-components";
import { Section } from "./globalStyles";

export const AgenciesSection = styled.div`
  display: block;
  margin: 2rem 5% 2rem 5%;
  padding: 0;
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`;
