import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { fetchBusinesses, fetchVehicleAvailability } from "../state";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
import { RidesSection as VehicleAvailabilitySection } from "../style/rides";
import { VehicleAvailabilityTable } from "../components/VehicleAvailabilityTable";
import { BallBeat } from "react-pure-loaders";
import { PaymentForm as VehicleAvailabilityForm } from "../style";
function VehicleAvailabilityContainer({
  businessesData,
  fetchBusinesses,
  vehicleavailability,
}) {
  const dispatch = useDispatch();

  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);
  var businessesOptions = businessesData
    .filter((business) => {
      return business.isActive === true;
    })
    .map((type) => (
      <option value={type.id} key={type.id} label={type.businessName}>
        {" "}
        {type.businessName}{" "}
      </option>
    ));
  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    if (businessesData.length === 0) {
      fetchBusinesses();
    }
  }, [fetchBusinesses]);
  // console.log(state, ridesFlag);
  const [businessAccountId, setBusinessAccountId] = useState({
    accountId: null,
  });

  return (
    <>
      <GlobalStyle />
      <Layout>
        <VehicleAvailabilitySection>
          <h2>Self Drive Vehicle Availability</h2>
          <br />
          {/* <VendorPayoutsForm businessesData={businessesData} /> */}
          <VehicleAvailabilityForm>
            <div
              style={{
                width: "100%",
                display: "flex",
                // alignItems: "center",
              }}
            >
              <label>
                <select
                  onChange={(e) => {
                    setBusinessAccountId({ accountId: Number(e.target.value) });
                  }}
                >
                  <option>Select a vendor</option>
                  {businessesOptions}
                </select>
              </label>
              <label>
                <button
                  style={{
                    width: "max-content",
                    height: "fit-content",
                    backgroundColor: "seagreen",
                    color: "white",
                    border: "none",
                    padding: ".5rem",
                    margin: "0 1rem",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    if (businessAccountId.accountId !== null) {
                      e.preventDefault();
                      //   console.log(businessAccountId);
                      dispatch(fetchVehicleAvailability(businessAccountId));
                    }
                  }}
                >
                  Get Vehicle Availability
                </button>
              </label>
            </div>
          </VehicleAvailabilityForm>
          {vehicleavailability.vehicleavailabilityloading ? (
            <h3>
              Loading Vehicle Availability List{" "}
              <span>
                <BallBeat
                  color={"#123abc"}
                  loading={vehicleavailability.vehicleavailabilityloading}
                />
              </span>{" "}
            </h3>
          ) : vehicleavailability.vehicleavailabilityerror ? (
            <>
              <h3>ERROR ⚠️ FETCHING Vehicle Availability</h3>
              <br />
              <p style={{ color: "grey" }}>
                {vehicleavailability.vehicleavailabilityerror}
              </p>
              <br />
              <br />
            </>
          ) : (
            <>
              {vehicleavailability.vehicleavailability.length > 0 ? (
                <>
                  <VehicleAvailabilityTable
                    vehicleavailability={
                      vehicleavailability.vehicleavailability
                    }
                  />
                </>
              ) : (
                "No vendor payouts found ⚠️"
              )}
            </>
          )}
        </VehicleAvailabilitySection>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    businessesData: state.vendors.businesses,
    vehicleavailability: state.agencies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBusinesses: () => dispatch(fetchBusinesses()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleAvailabilityContainer);
