import React, { useState, useEffect } from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";
import { updateVehicleAvailability } from "../state";
import { useDispatch } from "react-redux";
export const VehicleAvailabilityTable = ({
  vehicleavailability,
  vehicleavailabilitymessage,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    selectAll: 0,
    selected: {},
  });

  const toggleRow = (id) => {
    // console.log(state.selected);
    const newSelected = Object.assign({}, state.selected);
    newSelected[id] = !state.selected[id];
    // console.log(newSelected[id]);
    setState({
      selected: newSelected,
      selectAll: 2,
    });
  };
  const toggleSelectAll = () => {
    let newSelected = {};
    if (state.selectAll === 0) {
      vehicleavailability.forEach((x) => {
        newSelected[x.id] = true;
      });
      setState({
        selected: newSelected,
        selectAll: state.selectAll === 1 ? 0 : 1,
      });
      return;
    }
    if (state.selectAll === 2) {
      setState({
        selected: {},
        selectAll: 0,
      });
      return;
    }
    setState({
      selected: newSelected,
      selectAll: state.selectAll === 1 ? 0 : 1,
    });
  };

  const handleAvailability = (flag) => {
    // e.preventDefault()
    var ids = [];
    let data = [];

    var filone = Object.keys(state.selected).map((key) => {
      if (state.selected[key] === true) {
        return Number(key);
      } else {
        return null;
      }
    });
    filone.forEach((item) => item != null && ids.push(item));
    ids.forEach((item) => {
      let obj = {
        id: item,
        isActive: flag === "avail" ? 1 : 0,
      };
      data.push(obj);
    });
    // console.log(data);
    dispatch(updateVehicleAvailability(data));
  };

  const columns = [
    {
      Header: "S. No",
      id: "row",
      filterable: false,
      accessor: (va) => {
        var rowNum = vehicleavailability.indexOf(va) + 1;
        return rowNum;
      },
    },

    {
      id: "checkbox",
      accessor: "",
      filterable: false,
      Cell: ({ row }) => {
        return (
          <input
            style={{
              width: "2rem",
              alignSelf: "center",
            }}
            type="checkbox"
            // className="checkbox"
            checked={state.selected[row.original.id] === true}
            onChange={(e) => {
              e.preventDefault();
              console.log(e.nativeEvent);
              toggleRow(row.original.id);
            }}
          />
        );
      },
      Header: (x) => {
        return (
          <input
            style={{
              width: "2rem",
              alignSelf: "center",
            }}
            type="checkbox"
            // className="checkbox"
            checked={state.selectAll === 1}
            ref={(input) => {
              if (input) {
                input.indeterminate = state.selectAll === 2;
              }
            }}
            onChange={(e) => {
              e.preventDefault();
              toggleSelectAll();
            }}
          />
        );
      },
      sortable: false,
      width: 15,
    },
    {
      Header: "Category",
      accessor: "measureDesc",
      id: 52,
    },
    {
      Header: "Location",
      accessor: "locationName",
      id: 17,
      // minWidth: 150
    },
    {
      Header: "Car Name",
      accessor: "subCategoryDesc",
      // minWidth: 150
    },

    {
      Header: "Active",
      accessor: (data) => (data.isActive ? "Yes" : "No"),
      id: 10,
      // minWidth: 150
    },
  ];

  //   var data = vehicleavailability

  var tableRender = (
    <Table
      // getTdProps={(state, rowInfo, column, instance) => {
      //   return {
      //     // onClick: (e) => console.log("row clicked", rowInfo),
      //   };
      // }}
      // Pass the page url here to navigate to onClick
      path={""}
      // pass filename to enable .csv download
      //   filename={vendor}
      extras={true}
      vp={true}
      columns={columns}
      data={vehicleavailability}
    />
  );
  // console.log(vendorName.split(" ").join("_"));
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginRight: "1rem",
        }}
      >
        <button
          style={{
            width: "fit-content",
            height: "fit-content",
            backgroundColor: "ivory",
            color: "mediumseagreen",
            border: "none",
            padding: ".5rem",
            marginRight: "2rem",
            cursor: "pointer",
            border: "1.5px mediumseagreen solid",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleAvailability("avail");
          }}
        >
          Mark Available ✅
        </button>
        <button
          style={{
            width: "fit-content",
            height: "fit-content",
            backgroundColor: "ivory",
            color: "mediumvioletred",
            border: "none",
            padding: ".5rem",
            cursor: "pointer",
            border: "1.5px mediumvioletred solid",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleAvailability("not-avail");
          }}
        >
          Mark Not Available ❌
        </button>
      </div>
      <TableStyles extras={true} vp={true}>
        {vehicleavailability ? tableRender : " "}
      </TableStyles>
    </>
  );
};
